import {
  get, post, update, get2, upload, download
} from './helpers'

export function tambahDataInstansi($data) {
  return post('/instansi', $data)
}

export function uploadFileLogo(instansiId, file, onUploadProgress) {
  return upload(`/instansi/uploadfile/${instansiId}`, file, onUploadProgress)
}

export function downloadLogo($filename) {
  return download(`instansi/download/${$filename}`)
}

export function deleteInstansi($data) {
  return post('/instansi/delete', $data)
}



